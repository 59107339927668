import axios from "axios";

// const baseURL = "https://event.servepratham.com/";
const baseURL = "https://companywebsite-be.vercel.app/";

const http = axios.create({
  baseURL,
  timeout: 600000,
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

http.interceptors.response.use(
  (response) => {
    if (response?.data?.code != 200) {
    }
    return response;
  },
  (error) => {
    if (error.response) {
      console.error("Error response", error.response);
      if (error.response.status === 401) {
      }
    } else if (error.request) {
      console.error("Error request", error.request);
    } else {
      console.error("Error message", error.message);
    }
    return Promise.reject(error?.response?.data);
  }
);

export default http;
