import PhoneIcon from "../images/phone-icon.png";
import EmailIcon from "../images/mail-icon.png";
import LocationIcon from "../images/location-icon.png";
import TitleIcon from "../images/title.png";
import DescriptionIcon from "../images/description.png";

export const contactData = [
  {
    name: "Email",
    des: "info@ventionss.com",
    icon: EmailIcon,
  },
  {
    name: "Office Address",
    des: "New York, NY 10022",
    icon: LocationIcon,
  },
];

export const jobData = [
  {
    name: "Title",
    des: ["Blockchain developer"],
    icon: TitleIcon,
  },
  {
    name: "Salary",
    des: ["$100K/yr - $140K/yr  Remote  Part-time  Entry level"],
    icon: TitleIcon,
  },
  {
    name: "About the job",
    des: ["Welcome to Vention, your trusted partner in creating stunning, functional, and user-friendly websites that drive results. With a passion for innovation and a commitment to excellence, we are here to transform your online presence and help you achieve your digital goals."],
    icon: TitleIcon,
  },
  {
    name: "You Will Be Responsible For",
    des: [
      "Writing well-designed, testable and efficient code.",
      "Gathering and evaluating user feedback.",
      "Working as a part of a dynamic team to deliver winning products.",
      "Providing code documentation and other inputs to technical documents.",
      "Supporting continuous improvement by investigating alternatives and new technologies and presenting these for architectural review.",
      "Troubleshooting and debugging to optimize performance.",
      "Leading the design, creation, and implementation of a suite of software.",
      "Building a strong blockchain product offering.",
    ],
    icon: TitleIcon,
  },
  {
    name: "Ideal Profile",
    des: [
      "You possess a Degree/Diploma in Computer Science, Engineering or related field.",
      "You have at least 1 year experience, ideally within a Software Architect or Software Engineer role.",
      "You have working knowledge of Solidity, Crypto and Ethereum",
      "You are highly goal driven and work well in fast paced environments",
      "You possess strong analytical skills and are comfortable dealing with numerical data",
      "You are a self-starter and demonstrate a high level of resilience",
    ],
    icon: TitleIcon,
  },
  {
    name: "What's on Offer?",
    des: [
      "Work alongside & learn from best in class talent",
      "Flexible working options",
      "Attractive salary & benefits",
    ],
    icon: TitleIcon,
  },
];
