import NavBar from "../components/Navbar/NavBar";
import { jobData } from "./data";
import FbIcon from "../images/fb-icon.png";
import LinkedinIcon from "../images/linkedin-icon.png";
import { useState } from "react";
import axios from "axios";
import Notiflix from "notiflix";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import apiService from "../api/apiService";
import Footer from "../components/Footer";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  address: yup.string().required("Last name is required."),
  email: yup
    .string()
    .required("Email is required.")
    .email("Please enter valid email."),
  phone_number: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number must be 10 digits.")
    .max(10, "Phone number must be 10 digits.")
    .typeError("Phone number is required."),
  message: yup.string().required("Message is required."),
});

const ContactUs = () => {
  const [loading, setloading] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    address: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      address: "",
      email: "",
      phone_number: "",
      message: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setloading(true);

    try {
      const respsonse = await apiService.post("bids", data);
      if (respsonse) {
        Notiflix.Report.success("Success", respsonse?.message, "Okay");
        reset();
      }
    } catch (error) {
      Notiflix.Report.failure("Something went wrong!", "");
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <div>
      <NavBar />
      <div
        data-aos="zoom-in"
        className="h-full min-h-full items-center mt-8 w-full bg-white py-12 lg:py-24"
      >
        <div className="w-full ">
          <div className="contact-bg p-4 md:p-10 mt-2 md:mt-6 grid gap-4 md:gap-10">
            {jobData.map((item, index) => (
              <div key={index}>
                <div className="flex gap-3 items-start">
                  {/* <div className="w-[50px] min-w-[50px] mt-2">
                    <img
                      src={item.icon}
                      className="w-full h-full object-contain"
                    />
                  </div> */}
                  <div>
                    <div className="font-semibold text-[20px]">{item.name}</div>
                    <div className="mt-1 font-medium text-gray-600 md:text-[20px] ">
                      {item.des.length === 1 ? (
                        item.des
                      ) : (
                        <dl>
                          {item.des.map((item, index) => (
                            <dt>{"- " +item}</dt>
                          ))}
                        </dl>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6 w-full bg-[#1E3A8A] xl:py-20 lg:py-10 py-5 px-7 rounded-md">
          <p className="text-[34px] font-extrabold text-white text-center">
            Cover Letter
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 grid md:grid-cols-2 gap-5 2xl:flex">
              <div className="w-full 2xl:w-1/4">
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      name="first_name"
                      type="text"
                      placeholder="First Name *"
                      className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                    />
                  )}
                />
                {errors?.first_name?.message && (
                  <div className="text-sm mt-1 text-gray-100">
                    {errors?.first_name?.message}
                  </div>
                )}
              </div>
              <div className="w-full 2xl:w-1/4">
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      name="last_name"
                      type="text"
                      placeholder="Last Name *"
                      className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                    />
                  )}
                />
                {errors?.last_name?.message && (
                  <div className="text-sm mt-1 text-gray-100">
                    {errors?.last_name?.message}
                  </div>
                )}
              </div>
              <div className="w-full 2xl:w-1/4">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      name="email"
                      type="text"
                      placeholder="Email *"
                      className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                    />
                  )}
                />
                {errors?.email?.message && (
                  <div className="text-sm mt-1 text-gray-100">
                    {errors?.email?.message}
                  </div>
                )}
              </div>
              <div className="w-full 2xl:w-1/4">
                <Controller
                  name="phone_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      name="phone_number"
                      type="number"
                      placeholder="Phone *"
                      className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                    />
                  )}
                />
                {errors?.phone_number?.message && (
                  <div className="text-sm mt-1 text-gray-100">
                    {errors?.phone_number?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5 w-full">
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    name="address"
                    type="text"
                    placeholder="Address *"
                    className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                  />
                )}
              />
              {errors?.message?.message && (
                <div className="text-sm mt-1 text-gray-100">
                  {errors?.message?.message}
                </div>
              )}
            </div>
            <div className="mt-5 w-full">
              <Controller
                name="ghlink"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    name="ghlink"
                    type="text"
                    placeholder="Please share your GitHub profile link here*"
                    className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                  />
                )}
              />
              {errors?.message?.message && (
                <div className="text-sm mt-1 text-gray-100">
                  {errors?.message?.message}
                </div>
              )}
            </div>
            <div className="mt-5 w-full">
              <Controller
                name="ldlink"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    name="ldlink"
                    type="text"
                    placeholder="Please share your LinkedIn profile link here *"
                    className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                  />
                )}
              />
              {errors?.message?.message && (
                <div className="text-sm mt-1 text-gray-100">
                  {errors?.message?.message}
                </div>
              )}
            </div>
            <div className="mt-5 w-full">
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <textarea
                    {...field}
                    rows={4}
                    name="message"
                    type="text"
                    placeholder="Cover Letter *"
                    className="w-full border border-white rounded-md bg-transparent px-4 py-3 outline-none text-white placeholder:text-white"
                  />
                )}
              />
              {errors?.message?.message && (
                <div className="text-sm mt-1 text-gray-100">
                  {errors?.message?.message}
                </div>
              )}
            </div>

            <div className="mt-5">
              <button
                type="submit"
                disabled={loading}
                className="outline-none text-[#1E3A8A] font-semibold bg-white shadow-md w-full py-4 px-5"
              >
                {loading ? "Loading..." : "Apply"}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default ContactUs;
